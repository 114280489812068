.iq-ribbon-effect{
    right: 2rem;
    left: unset;
    >span{
        transform: rotate(0deg);
    }
    &.text-success{
        margin-right: 5em;
    }
    &.text-danger{
        margin-right: 10em;
    }
}