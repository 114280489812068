.uppy-Dashboard-browse {
    color: var(--#{$variable-prefix}primary);
}

.uppy-Dashboard-inner {
    width: 100% !important;
}

.uppy-Dashboard-inner {
    background-color: var(--bs-gray-900) !important;
    border-color: var(--bs-border-color);
}

.uppy-Dashboard-AddFiles-title {
    color: var(--bs-heading-color);
}