.dayrade-dashboard-container {
  width: 100%;
  height: calc(100vh - 9rem);
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;

  .dayrade-dashboard-charts {
    flex: 0 0 80%;
    max-width: 1260;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
  }

  .dayrade-chart-container {
    flex: 0 0 23.5%;
    width: 23.5%;
    max-width: 300px;
    height: 47.5%;
    margin-right: 1%;
    margin-bottom: 8px;
    transition: flex-basis 0.3s;
  }

  .dayrade-chart-container:not(.dayrade-selected-chart-container) {
    .dayrade-chart {
      cursor: pointer;

      &:hover {
        background-color: #ADB5BD;
        color: #ffffff;
      }
    }
  }

  .dayrade-chart-header {
    padding: 8px 1rem 4px;

    .dayrade-chart-header-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      border-bottom: 1px solid #d7d7d7;
    }

    .dayrade-chart-header-texts {
      width: 150px;
    }

    h4 {
      font-size: 14px;
      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide the overflow text */
      text-overflow: ellipsis; /* Add ellipsis at the end of truncated text (optional) */
    }

    button {
      border: none;
      background: none;
      color: #d7d7d7;

      &:hover {
        color: #cdff7b;
      }
    }
  }

  .dayrade-chart-body {
    padding: 0 1rem 4px;
  }

  .dayrade-selected-chart-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    height: 100%;
    max-width: 1260px;
    z-index: 2;

    .dayrade-chart {
      height: 100%;
      animation: dayrade-chart-animation 350ms linear;
    }

    .dayrade-chart-header {
      .dayrade-chart-header-texts {
        width: 80%;
      }
    }
  }

  .dayrade-not-selected-chart-container {
    display: none;
  }

  @keyframes dayrade-chart-animation {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 100%;
      height: 100%;
    }
  }

  .dayrade-dashboard-profile {
    flex: 0 0 20%;
    max-width: 298px;
    height: 100%;
    margin-top: 0;
  }

  .dayrade-dashboard-profile-image-header {
    padding: 0;
    margin: 1rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid #d7d7d7 !important;
  }

  .dayrade-dashboard-profile-image {
    max-inline-size: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
  }

  .dayrade-dashboard-about-info-container {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d7d7d7;
  }

  .dayrade-dashboard-job-title {
    color: #cdff7b;
  }

  .dayrade-dashboard-about-country-container {
    display: flex;
    align-items: center;
  }

  .dayrade-dashboard-about-country-name {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}


