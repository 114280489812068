.btn-icon {
    height: $btn-icon-height;
    width: $btn-icon-width;
    padding: 0;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
   &.btn{
        .btn-inner{
            display: inline-flex;
            svg{
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
    &.btn-sm {
        height: $btn-icon-height-sm;
        width: $btn-icon-width-sm;
        padding: 0;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .btn-inner{
            svg{
                width: 1rem;
                height: 1rem;
            }
        }
    }
    &.btn-lg {
        height: $btn-icon-height-lg;
        width: $btn-icon-width-lg;
        padding: 0;
        text-align: center;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        .btn-inner{
            svg{
                width: 2.5rem;
                height: 2.5rem;
            }
        }   
    }
}

.btn-setting {
    padding: $spacer * .5;
    z-index: 900 + 100;
    top: 50%;
}
.btn-landing {
    top: 50%;
    right: -3.6rem;
    left: auto;
    border-bottom-right-radius: 0;
    transform: rotate(270deg);
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0.25rem;
    padding: .25rem .5rem;
    position: fixed;
    z-index: 1;
}
.btn-fixed-end.btn-dashboard,
.btn-dashboard {
    top: 68%;
    right: -3rem;
    left: auto;
    border-bottom-right-radius: 0;
    transform: rotate(270deg);
    border-bottom-left-radius: 0rem;
    border-top-right-radius: 0.25rem;
    padding: .25rem .5rem;
    position: fixed;
    z-index: 99;
}
.customizer-btn{
    transform: rotate(-45deg);
}

.nav-pills {
    .nav-link {
        background: none;
        border: 0;
    }
}
.btn-group {
    .sr-only {
        vertical-align: middle;
    }
}