.shape{
    top: 16px;
    left: 16px;
    position: absolute;
    img {
        opacity: .3;
    }
}
.test-text{
    padding: 0em 13em;
}

#testimonial-one-slider{
    position: relative;
    &.slider-circle-btn{
        .swiper-button{
            width: 30px;
            height: 30px;
            position: absolute;
            &.swiper-button-next {
                right: 0.2rem;
                left:auto;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
                &:after {
                    content: 'next';
                    font-size:  0.75rem;;
                    color: $white;
                }
            }
            &.swiper-button-prev {
                &:after {
                    content: 'prev';
                    font-size:  0.75rem;;
                    color: $white;
                }
                right:auto;
                left: 0.2rem;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
            }
        }
    }
    
}
.user-test-img{
 height: 60px;
 width: 60px;
}
@include media-breakpoint-down(lg){
    .test-text{
        padding: 0em;
    }
}