.boxed-fancy {
    &::before {
        content: '';
        width: 100%;
        height: 200px;
        background: var(--#{$variable-prefix}primary);
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .boxed-inner {
        margin: $spacer * 2;
        box-shadow: $box-shadow;
        overflow: hidden;
        border-radius: $border-radius-lg;
        background: var(--bs-body-bg);
    }
}
.boxed {
    background: var(--#{$variable-prefix}primary);
    .boxed-inner {
        margin: $spacer * 2;
        box-shadow: $box-shadow;
        overflow: hidden;
        border-radius: $border-radius-lg;
        background: var(--bs-body-bg);
    }
}