.fc-unthemed .fc-toolbar .fc-button{
    text-transform: capitalize;
}
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td{
    border-color: var(--bs-border-color) !important;
}
.fc-other-month.fc-past, .fc-other-month.fc-future {
    background: var(--bs-body-bg);
}
.fc-unthemed td.fc-today{
    background: rgba(var(--bs-primary), .04) !important;
}
