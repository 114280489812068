@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  color-scheme: light dark;
  color: theme('colors.on-dark');
  background-color: theme('colors.background-dark');
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}

html {
  scroll-behavior: smooth;
}

body {
  @apply tw-min-h-screen tw-w-full  tw-bg-background-dark tw-text-on-dark;
  overflow-x: hidden;
}

input[disabled] {
  @apply tw-cursor-not-allowed tw-opacity-50;
}

/* @media (prefers-color-scheme: light) {
  :root {
    color: theme('colors.on-light');
    background-color: theme('colors.background-light');
  }
} */
