// nav-full-width

.sidebar{
    &.navs-full-width{
        .sidebar-body{
            padding-right: 0;
            .nav-item {
                &.static-item {
                    padding: 0;
                }
                padding: 0;
            }
        }
        &.sidebar-mini{
            &.sidebar-base:not(.sidebar-hover:hover){
                .navbar-nav{
                    .nav-item{
                        &.static-item{
                            padding: 0;
                        }
                        &:not(.static-item){
                            .nav-link{
                                padding: 0.5rem 1rem 0.5rem 1.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
