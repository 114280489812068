$prefix: theme-;
.#{$prefix}bordered {
    .sidebar,
    .iq-navbar,
    .dropdown-menu,
    .card {
        border: var(--bs-border-width) solid var(--bs-border-color);
    }
}
.#{$prefix}flat{
    .sidebar,
    .iq-navbar,
    .card, .nav-link {
        box-shadow: unset;
    }
}
.#{$prefix}sharp{
    .sidebar,
    .iq-navbar,
    .dropdown-menu,
    .btn,
    .btn-border,
    .rounded,
    .form-control,
    .input-group,
    .btn-group,
    .input-group-text,
    .rounded
    .card,
    .card-header,
    .card-footer, div, ul, li, button, span, a,
    .img-thumbnail,
    .rounded-pill
    .card-img,
    .card-img-top{
        border-radius: 0 !important;
    }
}
.#{$prefix}without-animation{
    *,::after,::before{
        transition: unset !important;
        animation: unset !important;
    }
}