
.sidebar {
    &.sidebar-hover {
        &:hover {
            --sidebar-width: #{$navbar-vertical-width};
            max-width: var(--sidebar-width);
            .sidebar-logo {
                transform: translateX(0%);
                opacity: 1;
            }
            .navbar-brand {
                .logo-title {
                    transform: translateX(0%);
                    opacity: 1;
                }
            }
        }
        &.sidebar-mini{
            &.sidebar-transparent{
                &:hover{
                    +.main-content{
                        --sidebar-width: #{$navbar-vertical-width};
                    }
                }
            }
        }
    }
}
@include media-breakpoint-down(xl) {
    .sidebar {
        &.sidebar-hover {
            &:hover {
                &.sidebar-mini{
                    &.sidebar-transparent{
                        &:hover{
                            +.main-content{
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
