.iq-email-content {
    .iq-email-date {
        right: unset;
        left: -19rem;
    }
}

.iq-email-listbox {
    .iq-email-sender-list {
        li:hover {
            .iq-social-media {
                left: 0;
                right: 93rem;
                padding: 0 1.25rem;
            }
        }
    }
}

.iq-social-media {
    margin: 0;
    padding: 0;
    float: left;
    left: -5.688rem;
    right: unset !important; //!important is use because it's been stuck while hovering 
}

.iq-email-content {
    .iq-email-subject {
        left: -7.125rem;
        right: 17rem;
    }
}

.iq-email-sender-info {
    .iq-email-title {
        left: 0;
        right: 90px;
    }
}

.email-app-details.show {
    transform: translateX(0%);
    overflow-y: scroll;
}

.email-app-details {
    transform: translateX(-100%);
    overflow-y: scroll;
}

@include media-breakpoint-down(sm) {
    .iq-email-content {
        .iq-email-subject {
            left: 12rem;
            right: unset;
        }
    }
}