.auth-page {
  @apply tw-flex tw-h-full tw-min-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center;
  @apply tw-p-4 lg:tw-p-6;
}

.auth-heading {
  @apply heading1 tw-text-on-dark-emphasized;
}

.auth-main {
  @apply tw-mt-4 tw-max-w-full;
  width: 600px;
}

.auth-form-container {
  @apply tw-w-full tw-rounded-lg tw-border-2 tw-p-4;
}

.register-form-row {
  @apply tw-py-1;
}

.login-form-row {
  @apply tw-py-2;
}

.auth-btn-container {
  @apply tw-flex tw-items-center tw-justify-center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.auth-btn {
  @apply tw-cursor-pointer tw-rounded-md tw-px-6 tw-py-2;
}

.auth-submit-btn {
  @apply tw-bg-primary-dark tw-font-medium tw-text-background-dark;
  @apply tw-transition-all tw-duration-150 tw-ease-out hover:tw-bg-secondary-dark;
}

.auth-form-bottom {
  @apply tw-text-center;
}

.auth-form-feedback-container {
  @apply tw-mb-3 tw-rounded-md tw-border-error-dark tw-p-2 tw-text-center;
  border-width: 1px;
}

.auth-form-feedback-heading {
  @apply heading2 tw-text-on-dark-emphasized;
}

.auth-form-feedback-message {
  @apply tw-text-xs tw-text-error-dark md:tw-text-sm;
}
