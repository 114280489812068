.header-hover-menu{
    .iq-nav-menu {
        li {
            &.active{
                >a {
                    color: var(--#{$variable-prefix}primary);
                }
            }
            .nav-link{
                &.active{
                    color: var(--#{$variable-prefix}primary);
                    font-weight: 500;
                }
            }
        }
    }
}
.header-hover-menu{
    &.iq-nav-menu {
        .nav-item {
            >.nav-link {
                &:hover {
                    color: var(--#{$variable-prefix}primary);
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .header-hover-menu{
        .iq-nav-menu {
            li {
                &:hover {
                    >ul {
                        display: block;
                    }
                }
               
                &.active{
                    >a {
                        color: var(--#{$variable-prefix}primary);
                        &::after {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 2px;
                            border-radius: var(--bs-border-radius);
                            background: var(--#{$variable-prefix}primary);
                            bottom: -0.7rem;
                            left: 0;
                            top: unset;
                            z-index: -1;
                        }
                    }
                }
            }
            >li {
                &:hover {
                    border-color: transparent;
                    padding: 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .header-hover-menu{
        .iq-nav-menu {
            li {
                &.active{
                    &::after {
                        content: '';
                        position: absolute;
                        width: 4px;
                        height: 100%;
                        top: 0;
                        border-radius: var(--bs-border-radius);
                        background: var(--#{$variable-prefix}primary);
                        left: 0;
                        z-index: -1;
                    }
                }
            }
        }
    }
    .header-hover-menu{
        &.iq-navbar{
            .navbar-collapse{
                &:not(.offcanvas-collapse){
                    position: absolute;
                    top: 96%;
                    left: 0;
                    width: 100%;
                    flex-grow: unset;
                    background:var(--bs-gray-900);
                    z-index: 1081;
                    box-shadow: var(--bs--box-shadow-sm);
                    transition: all 400ms ease;
                }
            }
        }
    }
}
@keyframes menu-sub-dropdown-animation-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes menu-sub-dropdown-animation-move-up {
    from {
        margin-top: 0.75rem;
    }
    to {
        margin-top: 0;
    }
}
@keyframes menu-sub-dropdown-animation-move-down {
    from {
        margin-bottom: 0.75rem;
    }
    to {
        margin-bottom: 0;
    }
}
