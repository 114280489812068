@layer components {
  .double-input-form-control {
    @apply form-control tw-w-1/2 tw-rounded-none tw-px-3 tw-py-3 tw-text-xs tw-leading-3;
    @apply sm:tw-text-sm;
  }

  .double-input-form-control-left {
    @apply tw-rounded-s-lg;
    border-right-width: 0.5px;
  }

  .double-input-form-control-right {
    @apply tw-rounded-e-lg;
    border-left-width: 0.5px;
  }
}

@media (min-width: 640px) {
  .double-input-form-control {
    line-height: 14px;
  }
}
