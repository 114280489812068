.sidebar {
    &.sidebar-glass{
        background-color: rgba($white, .1);
        border-right: 1px solid var(--bs-gray-900);
        backdrop-filter: blur(10px);
        &.sidebar-boxed{
            border: 1px solid var(--bs-gray-900);
        }
    }
}
