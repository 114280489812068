//blog-treding
.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: -30px;
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 30px;
    background-clip: padding-box;
}

//modals
.iq-modal {
    >.modal-dialog {
        max-width: 1400px;
    }
}

//file-manager dropzone
.file-manager-modal {
    .dzu-dropzone {
        min-height: 500px;
        overflow: hidden;
        border: 1px solid #8a92a6;
    }
}

.dark {
    .file-manager-modal {
        .dzu-dropzone {
            background-color: #151824;
        }
    }
}

.btn-upload {
    input[type="file"]::file-selector-button {
        background-color: var(--bs-primary);
        border-radius: 0.25rem;
        box-shadow: none;
        color: white;
        border: 1px solid var(--bs-primary);

    }

    input[type="file"]::file-selector-button:hover {
        background-color: rgba(var(--bs-primary), 0.9);
    }
}



//dark choices

.dark .choices[data-type*=select-one] {
    .choices__input {
        background: #222738;
        border-color: rgba(255, 255, 255, .1) !important;
    }
}

.dark .is-open .choices__list[aria-expanded] {
    border-color: rgba(#ffff, .1);
}


//full-calendar
.fc-button-primary {
    background-color: #3a57e8 !important;
    border-color: #3a57e8 !important;
}

//select2

[data-bs-theme=dark] .css-13cymwt-control,
.css-t3ipsp-control {
    background-color: var(--bs-gray-900) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    box-shadow: none;
    color: var(--bs-body-color);
}

[data-bs-theme=dark] .css-1nmdiq5-menu {
    background-color: var(--bs-gray-900) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    box-shadow: none;
    color: var(--bs-body-color);
}

[data-bs-theme=dark] .css-166bipr-Input input,
.css-1dimb5e-singleValue {
    color: var(--bs-body-color) !important
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: var(--bs-nav-tabs-link-active-bg) !important;
}

.tab-bottom-bordered .nav-tabs .nav-link.active {
    background-color: unset !important;
}

.custom-active .nav-link.active {
    background-color: transparent !important;
}

//for design-sytem breadcrumb issue

.breadcrumb_svgIcon__SM3dJ {
    .breadcrumb {
        margin-bottom: 0 !important;
    }
}