.dayrade-sidebar {
  padding-bottom: 3.8rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.dayrade-sidebar-nav {
  flex: 1;
}

.sidebar-footer-divider {
  margin-left: 1rem;
  height: 1px;
  background-color: #d7d7d7;
}

.dayrade-screensize-page-container {
  width: 100%;
  height: calc(100vh - 9rem);
  overflow: hidden;
}

.dayrade-link {
  color: #d7d7d7;

  &:hover {
    color: #cdff7b;
  }
}

.flag-country-select-options-container {
  max-height: 12rem;
}

.flag-country-select-icon-container {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
  border-radius: 9999px;
}

.flag-country-select-icon {
  width: 1.5rem;
  height: 1.5rem;
  object-fit: cover;
}
