.myChart {
    min-height: 230px !important;
}

.d-activity {
    min-height: 230px !important;
}

.d-main {
    min-height: 245px !important;
}

.apexcharts-canvas svg .apexcharts-datalabels-group .apexcharts-datalabel-value {
    fill: var(--bs-gray-400);
}

.apexcharts-legend-text {
    color: var(--bs-gray-400) !important;
}