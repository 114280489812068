.dayrade-comparision-container {
	width: 100%;
	height: 100%;
	// display: flex;
	// flex-direction: row;
	// align-items: stretch;
	// justify-content: space-between;

	.dayrade-comparision-calendar-container {
		flex: 100% 0 0;
		max-width: 100%;
		margin-top: 0;
		// padding-left: 0.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
		// overflow-x: scroll;
		// overflow-y: hidden;
	}
	.dayrade-comparision-calendar-wrapper {
		// height: 100%;
		margin-top: 0;
	}
	.dayrade-comparision-calendar-scrollcontainer {
		height: 100%;
	}
	.dayrade-comparision-table {
		border-collapse: separate;
		border-spacing: 0 10px;
		background-color: #151824;

		tr {
			background-color: #151824;
		}

		tr th:first-child,
		tr td:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		tr th p {
			padding-bottom: 10px;
		}
		tr th p,
		tr td p {
			color: #ffffff;
			font-size: 14px;
		}

		tr th:last-child,
		tr td:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		thead tr th {
			background-color: #222738 !important;
		}

		tr:nth-child(even) {
			background-color: #222738; /* Light gray for even rows */
		}

		tr:nth-child(odd) {
			background-color: rgba(33, 33, 33, 0.6); /* White for odd rows */
		}
		tr td {
			white-space: wrap;
		}
		.select-profile {
			padding: 10px;
			border-radius: 10px;
			border: 1px solid #2a2f3f;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			column-gap: 10px;
		}
		.search-container {
			display: flex;
			align-items: center;
			padding: 10px 10px;
			border-radius: 5px;
			background-color: #dbdbdb;

			// box-shadow:#151824 0 2px 5px rgba(0, 0, 0, 0.1),
		}
		.search-input {
			border: none;
			outline: none;
			background-color: #dbdbdb;
			font-size: 12px;
			color: #212121;
			flex: 1;
		}
		.search-button {
			background: none;
			border: none;
			cursor: pointer;
			display: flex;
			align-self: center;
			justify-content: center;
		}
	}
}
