@layer components {
  .responsive-img {
    max-inline-size: 100%;
    height: auto;
    object-fit: cover;
  }

  .link {
    @apply tw-text-on-dark tw-no-underline;
  }

  .underlined-link {
    @apply tw-text-blue-500 tw-underline tw-underline-offset-2;
  }

  .heading1 {
    @apply tw-text-3xl tw-font-bold md:tw-text-4xl lg:tw-text-5xl;
  }

  .heading2 {
    @apply tw-text-2xl tw-font-semibold md:tw-text-3xl lg:tw-text-4xl;
  }

  .heading3 {
    @apply tw-text-xl tw-font-medium md:tw-text-2xl lg:tw-text-3xl;
  }

  .heading4 {
    @apply tw-text-[16px] tw-font-medium md:tw-text-lg lg:tw-text-xl;
  }

  .heading5 {
    @apply tw-text-sm tw-font-medium;
  }

  .title {
    @apply tw-text-lg tw-font-medium md:tw-text-xl lg:tw-text-2xl;
  }

  .text1 {
    @apply tw-text-sm tw-font-normal;
  }

  .text2 {
    @apply tw-text-xs tw-font-normal md:tw-text-sm;
  }

  .loading {
    opacity: 0.25;
    transition: opacity 200ms;
    transition-delay: 200ms;
  }
}

@media (min-width: 768px) {
  .heading5,
  .text1 {
    font-size: 16px;
  }
}
