.parent {
	display: flex;
	justify-content: space-between;
	gap: 10px;
	height: 100%;
}
.child-1 {
	height: 100%;
	background-color: none;
	justify-content: center;
	align-items: center;
	width: auto;
}
.participants-box {
	height: 100%;
	display: flex;
	flex-direction: "row";
	justify-content: space-around;
	background-color: rgba(61, 61, 62, 1);
	border: 2px solid rgba(215, 215, 215, 1);
	border-radius: 10px;
	padding: 1px;
}

.participants-box:hover, .participants-box.active-participant {
    border: 2px solid rgba(205, 255, 123, 1);
}

.participant-image {
	height: 100%;
	width: 50%;
	border-radius: 5px;
	object-fit: cover;
	width: 50%;
	border-radius: 5px;
	object-fit: cover;
}
.participant-image-detail {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 8px;
	justify-content: space-around;
}
.notification-container {
	height: 100%;
	background-color: #cdff7b;
	justify-content: center;
	align-items: center;
	width: auto;
}

div.scrollmenu {
	overflow: auto;
	overflow-y: hidden;
	white-space: nowrap;
	height: 100%;
	padding: 5px 0;
}
div.scrollmenu::-webkit-scrollbar {
	display: none;
}

div.scrollmenu span{
	height: 100%;
	width: "auto";
	display: inline-block;
	color: white;
	text-align: center;
	margin: 0 5px;
	text-decoration: none;
}

.graph-container {
	border-radius: 10px;
	background-color: #222738;
	padding: 10px;
}
.view-button {
	border-radius: 30px;
	padding: 0 5px;
	height: auto;
	color: #cdff7b;
	font-size: "10px";
	background-color: #3e3e3f;
}
.participants-image {
	width: 57.48px;
	height: 55.25px;
	margin-left: -5px;
	border: 2px solid #222738;
	border-radius: 50%;
}
.following-vote-button {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 8px;
	border-radius: 5px;
	background-color: gray;
}
.following-vote-button-text {
	color: #cdff7b;
	display: flex;
	align-self: center;
	font-size: 12px;
}
.following-vote-button-numeric {
	display: flex;
	align-self: center;
	background-color: #cdff7b;
	font-size: 14px;
	padding: 0 10px;
	border-radius: 24px;
	font-size: 10px;
	color: black;
}
