.settings-page-container {
  @apply tw-px-4 lg:tw-px-0 lg:tw-pr-2;
}

.tabs-container {
  padding-top: 6px;
  padding-bottom: 6px;
}

.horizontal-tabs-container {
  @apply tw-hidden tw-items-center tw-justify-start tw-gap-x-2 tw-rounded-lg;
  @apply tw-px-3 md:tw-flex;
  background-color: #f9fafb;
}

.tabs-select-container {
  @apply md:tw-hidden;
}

.tab {
  @apply tw-rounded-md tw-px-4 tw-py-2 tw-text-sm tw-leading-5 hover:tw-bg-primary-light;
  color: #667085;
}

.selected-tab {
  @apply tw-bg-background-dark hover:tw-bg-background-dark;
  color: #eaecf0;
}

.tabpanels-container {
  @apply tw-py-6;
}

.settings-tabpanel-heading-container {
  @apply tw-border-b tw-border-on-dark tw-pb-3;
}

/* Form */

.settings-form-row {
  @apply tw-border-b tw-border-b-on-dark tw-py-1 md:tw-py-2;
}

.settings-form-inner-row {
  @apply tw-flex tw-flex-col md:tw-flex-row;
  max-width: 1200px;
}

.settings-label-container {
  @apply tw-mt-2 md:tw-mt-5;
  max-width: 100%;
  flex: 100%;
}

.settings-inputs-container {
  max-width: 100%;
  flex: 100%;
}

.settings-input-container {
  @apply tw-my-2 tw-flex tw-w-full tw-flex-col tw-items-center md:tw-my-5 lg:tw-flex-row;
}

.settings-input-field-container {
  max-width: 100%;
  flex: 100%;
}

.settings-label {
  @apply tw-border-r-on-dark tw-py-3 tw-pl-3 tw-text-xs tw-leading-[16px];
  @apply tw-text-on-dark sm:tw-text-sm;
  line-height: 16px;
}

.settings-input {
  @apply form-control tw-w-full tw-rounded-lg tw-px-3 tw-py-3 tw-text-xs tw-leading-3;
  @apply sm:tw-text-sm;
}

.settings-special-input {
  @apply settings-input tw-px-0 tw-pr-3;
}

.settings-textarea {
  @apply form-control tw-w-full tw-rounded-lg tw-px-3 tw-py-3 tw-text-xs tw-leading-4;
  @apply sm:tw-text-sm sm:tw-leading-5;
}

.settings-checkbox-field-container {
  @apply tw-mt-3 tw-flex tw-justify-center tw-gap-x-4 lg:tw-mt-0;
  max-width: 100%;
  flex: 100%;
}

.settings-image-field-container {
  @apply tw-flex tw-flex-col tw-items-center tw-justify-between md:tw-flex-row md:tw-items-start;
}

.settings-profile-image {
  @apply tw-rounded-lg tw-border-2 tw-border-primary-dark tw-object-cover;
  height: 300px;
  width: 300px;
}

.setings-profile-image-label {
  @apply tw-text-primary-dark;
}

.setings-profile-image-delete-btn {
  @apply tw-mr-4 tw-text-on-dark;
}

.settings-profile-image-file-input-container {
  @apply tw-px-4 tw-text-center;
}

.settings-job-title-checkbox-container {
  @apply tw-mt-2 tw-text-xs sm:tw-text-sm;
}

.settings-profile-btn-container {
  @apply tw-flex tw-items-center tw-justify-end tw-gap-x-2 tw-py-3;

  input {
    @apply tw-rounded-lg tw-px-3 tw-py-2 tw-font-medium tw-transition-all;
  }

  input[type='reset'] {
    @apply tw-border tw-border-on-dark tw-bg-background-dark tw-text-on-dark hover:tw-bg-black;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  input[type='submit'] {
    @apply tw-bg-primary-dark tw-text-on-util-dark hover:tw-bg-secondary-dark;
  }
}

.settings-btn {
  @apply tw-rounded-lg tw-px-3 tw-py-2 tw-font-medium tw-transition-all;
}

.settings-btn-submit {
  @apply tw-bg-primary-dark tw-text-on-util-dark hover:tw-bg-secondary-dark;
}

.settings-sterling-key-btn-container {
  @apply tw-items-start tw-justify-center;
}

@media (min-width: 640px) {
  .settings-input {
    line-height: 14px;
  }
}

@media (min-width: 768px) {
  .settings-label-container {
    max-width: 30%;
    flex: 30%;
  }

  .settings-inputs-container {
    max-width: 70%;
    flex: 70%;
  }

  .settings-profile-image {
    height: 100px;
    width: 100px;
  }
}

@media (min-width: 1024px) {
  .settings-input-field-container {
    max-width: 65%;
    flex: 65%;
  }

  .settings-checkbox-field-container {
    max-width: 35%;
    flex: 35%;
  }

  .settings-profile-image {
    height: 130px;
    width: 130px;
  }
}
